var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "queryStatistics" },
    [
      _c(
        "Row",
        { staticStyle: { "flex-wrap": "nowrap", height: "100%" } },
        [
          _vm.outExpand
            ? _c(
                "Col",
                {
                  style: _vm.outLeftSpan,
                  attrs: { height: "calc(100% - 32px)" },
                },
                [
                  _c(
                    "Card",
                    { staticClass: "card" },
                    [
                      _c("Alert", { staticClass: "ivu-alert" }, [
                        _vm._v("团检订单"),
                      ]),
                      !_vm.queryTime
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "nowrap",
                                "align-items": "center",
                                "margin-bottom": "10px",
                              },
                            },
                            [
                              _c("DatePicker", {
                                attrs: {
                                  type: "date",
                                  transfer: "",
                                  placeholder: "开始时间",
                                },
                                on: { "on-change": _vm.datePickerStartTime },
                                model: {
                                  value: _vm.unitParam.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.unitParam, "startDate", $$v)
                                  },
                                  expression: "unitParam.startDate",
                                },
                              }),
                              _vm._v("  ~  "),
                              _c("DatePicker", {
                                attrs: {
                                  type: "date",
                                  transfer: "",
                                  placeholder: "结束时间",
                                },
                                on: { "on-change": _vm.datePickerEndTime },
                                model: {
                                  value: _vm.unitParam.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.unitParam, "endDate", $$v)
                                  },
                                  expression: "unitParam.endDate",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("Input", {
                        staticClass: "search-input",
                        attrs: {
                          suffix: "ios-search",
                          clearable: "",
                          placeholder: "请输入关键字",
                        },
                        on: { input: _vm.inputSearchChange },
                        model: {
                          value: _vm.unitParam.groupUnitName,
                          callback: function ($$v) {
                            _vm.$set(_vm.unitParam, "groupUnitName", $$v)
                          },
                          expression: "unitParam.groupUnitName",
                        },
                      }),
                      _c(
                        "Checkbox",
                        {
                          staticStyle: { margin: "10px 0" },
                          attrs: {
                            indeterminate: _vm.indeterminate,
                            value: _vm.checkAll,
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleCheckAll.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("全选 ")]
                      ),
                      !_vm.queryTime
                        ? _c(
                            "Button",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: { type: "small" },
                              on: { click: _vm.resetClick },
                            },
                            [_vm._v("重置")]
                          )
                        : _vm._e(),
                      _vm.groupOrderData.length > 0
                        ? _c(
                            "Row",
                            {
                              staticStyle: {
                                "overflow-y": "hidden",
                                "overflow-x": "hidden",
                              },
                            },
                            [
                              _c(
                                "CheckboxGroup",
                                {
                                  staticClass: "radio-group",
                                  on: { "on-change": _vm.unitChange },
                                  model: {
                                    value: _vm.radioValue,
                                    callback: function ($$v) {
                                      _vm.radioValue = $$v
                                    },
                                    expression: "radioValue",
                                  },
                                },
                                _vm._l(
                                  _vm.groupOrderData,
                                  function (item, index) {
                                    return _c(
                                      "Row",
                                      { key: index, staticClass: "row-border" },
                                      [
                                        _c(
                                          "Col",
                                          {
                                            staticClass: "radio-group-radio",
                                            attrs: { span: 3 },
                                          },
                                          [
                                            _c("Checkbox", {
                                              staticClass: "label",
                                              attrs: { label: item.id },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "Col",
                                          {
                                            staticClass: "radio-group-content",
                                            attrs: { span: 21 },
                                          },
                                          [
                                            _c(
                                              "Row",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "6px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "Col",
                                                  {
                                                    staticClass: "date",
                                                    attrs: { span: 20 },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.orderCode)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "Row",
                                              [
                                                _c(
                                                  "Col",
                                                  {
                                                    staticStyle: {
                                                      overflow: "hidden",
                                                      "text-overflow":
                                                        "ellipsis",
                                                      "white-space": "nowrap",
                                                    },
                                                    attrs: {
                                                      span: "24",
                                                      title: item.groupUnitName,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.groupUnitName
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                              _c(
                                "Row",
                                [
                                  _c(
                                    "Col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c("Page", {
                                        attrs: {
                                          styles: "justify-content: flex-start",
                                          current: _vm.unitParam.pageNumber,
                                          "page-size": _vm.unitParam.pageSize,
                                          total: _vm.totals,
                                          simple: "",
                                        },
                                        on: { "on-change": _vm.changePageNum },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          staticStyle: {
                                            width: "90px",
                                            height: "2.6vh",
                                          },
                                          attrs: { size: "small" },
                                          on: {
                                            "on-change":
                                              _vm.SelectChangePageSize,
                                          },
                                          model: {
                                            value: _vm.queryFrom.pageSize,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryFrom,
                                                "pageSize",
                                                $$v
                                              )
                                            },
                                            expression: "queryFrom.pageSize",
                                          },
                                        },
                                        _vm._l(
                                          _vm.pageSizeList,
                                          function (item) {
                                            return _c(
                                              "Option",
                                              {
                                                key: item.value,
                                                attrs: { value: item.value },
                                              },
                                              [_vm._v(_vm._s(item.label))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("no-data"),
                      _vm.unitLoading
                        ? _c("Spin", { attrs: { fix: "" } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "expand" },
            [
              _c("Icon", {
                staticClass: "icon",
                attrs: { type: _vm.outExpandIcon, size: "16" },
                on: { click: _vm.outChangeExpand },
              }),
            ],
            1
          ),
          _c(
            "Col",
            { style: _vm.outSpan, attrs: { height: "calc(100% - 32px)" } },
            [
              _vm.groupOrderData.length > 0
                ? _c(
                    "Card",
                    { ref: "colHeight", staticStyle: { height: "100%" } },
                    [
                      _c(
                        "Row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "Col",
                            { attrs: { span: "24" } },
                            [
                              _c(
                                "Form",
                                {
                                  ref: "queryForm",
                                  attrs: { "label-width": 80, inline: "" },
                                },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "体检编号" } },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          placeholder: "请输入体检编号",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.queryFrom.testNum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryFrom,
                                              "testNum",
                                              $$v
                                            )
                                          },
                                          expression: "queryFrom.testNum",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "检查种类" } },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          staticStyle: { width: "185px" },
                                          attrs: {
                                            transfer: "",
                                            "label-in-value": "",
                                          },
                                          model: {
                                            value: _vm.queryFrom.workStateCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryFrom,
                                                "workStateCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryFrom.workStateCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.workStateCodeArr,
                                          function (item, index) {
                                            return _c(
                                              "Option",
                                              {
                                                key: item.id,
                                                attrs: { value: item.value },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.title) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "姓名" } },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          placeholder: "请输入姓名",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.queryFrom.personName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryFrom,
                                              "personName",
                                              $$v
                                            )
                                          },
                                          expression: "queryFrom.personName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "身份证号" } },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          placeholder: "请输入身份证号",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.queryFrom.idCard,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryFrom,
                                              "idCard",
                                              $$v
                                            )
                                          },
                                          expression: "queryFrom.idCard",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.drop
                                    ? _c(
                                        "FormItem",
                                        { attrs: { label: "危害因素" } },
                                        [
                                          _c("TreeSelect", {
                                            directives: [
                                              {
                                                name: "width",
                                                rawName: "v-width",
                                                value: 150,
                                                expression: "150",
                                              },
                                            ],
                                            ref: "hazardFactors",
                                            attrs: {
                                              data: _vm.hazardFactorsPriority,
                                              clearable: "",
                                            },
                                            on: {
                                              "on-change":
                                                _vm.hazardFactorsChange,
                                            },
                                            model: {
                                              value:
                                                _vm.queryFrom.hazardFactors,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.queryFrom,
                                                  "hazardFactors",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "queryFrom.hazardFactors",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.drop
                                    ? _c(
                                        "FormItem",
                                        { attrs: { label: "状态" } },
                                        [
                                          _c(
                                            "Select",
                                            {
                                              staticStyle: { width: "185px" },
                                              attrs: { transfer: "" },
                                              model: {
                                                value: _vm.queryFrom.isPass,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.queryFrom,
                                                    "isPass",
                                                    $$v
                                                  )
                                                },
                                                expression: "queryFrom.isPass",
                                              },
                                            },
                                            [
                                              _c(
                                                "Option",
                                                { attrs: { value: "89" } },
                                                [_vm._v("全部")]
                                              ),
                                              _c(
                                                "Option",
                                                { attrs: { value: "88" } },
                                                [_vm._v("已总检")]
                                              ),
                                              _c(
                                                "Option",
                                                { attrs: { value: "87" } },
                                                [_vm._v("待总检")]
                                              ),
                                              _c(
                                                "Option",
                                                { attrs: { value: "2" } },
                                                [_vm._v("在体检")]
                                              ),
                                              _c(
                                                "Option",
                                                { attrs: { value: "1" } },
                                                [_vm._v("未登记")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.drop
                                    ? _c(
                                        "FormItem",
                                        { attrs: { label: "性别" } },
                                        [
                                          _c(
                                            "Select",
                                            {
                                              staticStyle: { width: "185px" },
                                              attrs: {
                                                transfer: "",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.queryFrom.sex,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.queryFrom,
                                                    "sex",
                                                    $$v
                                                  )
                                                },
                                                expression: "queryFrom.sex",
                                              },
                                            },
                                            [
                                              _c(
                                                "Option",
                                                { attrs: { value: "男" } },
                                                [_vm._v("男")]
                                              ),
                                              _c(
                                                "Option",
                                                { attrs: { value: "女" } },
                                                [_vm._v("女")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.drop
                                    ? _c(
                                        "FormItem",
                                        { attrs: { label: "体检日期" } },
                                        [
                                          _c("DatePicker", {
                                            staticStyle: { width: "185px" },
                                            attrs: {
                                              transfer: "",
                                              clearable: false,
                                              placeholder: "请选择",
                                              type: "daterange",
                                            },
                                            on: {
                                              "on-change": _vm.registDateChange,
                                            },
                                            model: {
                                              value: _vm.selectDate,
                                              callback: function ($$v) {
                                                _vm.selectDate = $$v
                                              },
                                              expression: "selectDate",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.drop
                                    ? _c(
                                        "FormItem",
                                        { attrs: { label: "体检结论" } },
                                        [
                                          _c(
                                            "Select",
                                            {
                                              staticStyle: { width: "185px" },
                                              model: {
                                                value:
                                                  _vm.queryFrom.conclusionCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.queryFrom,
                                                    "conclusionCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "queryFrom.conclusionCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.conclusionList,
                                              function (item, i) {
                                                return _c(
                                                  "Option",
                                                  {
                                                    key: item.id,
                                                    attrs: {
                                                      value: item.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "FormItem",
                                    {
                                      staticClass: "br",
                                      staticStyle: { "margin-left": "-35px" },
                                    },
                                    [
                                      _c(
                                        "Button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click:
                                              _vm.getNoCheckProjectPersonSearch,
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                      _c(
                                        "Button",
                                        {
                                          attrs: { type: "primary" },
                                          on: { click: _vm.handleReset },
                                        },
                                        [_vm._v("重置")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "drop-down",
                                          on: { click: _vm.dropDown },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.dropDownContent) +
                                              " "
                                          ),
                                          _c("Icon", {
                                            attrs: { type: _vm.dropDownIcon },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c("Col", { attrs: { span: "2" } }, [
                            _c("span", { staticClass: "txt" }, [
                              _vm._v("体检完成率："),
                            ]),
                          ]),
                          _c(
                            "Col",
                            { attrs: { span: "15" } },
                            [
                              _c("Progress", {
                                attrs: {
                                  percent: _vm.physicalFinishRate,
                                  "stroke-width": 20,
                                  "text-inside": "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        { staticStyle: { margin: "10px 0 20px 0" } },
                        [
                          _c("Col", { attrs: { span: "2" } }, [
                            _c("span", { staticClass: "txt" }, [
                              _vm._v("评价完成率："),
                            ]),
                          ]),
                          _c(
                            "Col",
                            { attrs: { span: "15" } },
                            [
                              _c("Progress", {
                                attrs: {
                                  percent: _vm.evaluateFinishRate,
                                  "stroke-width": 20,
                                  "text-inside": "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        { attrs: { justify: "end" } },
                        [
                          _c("Table", {
                            ref: "table",
                            staticStyle: { width: "100%" },
                            attrs: {
                              loading: _vm.tableLoading,
                              border: "",
                              columns:
                                this.physicalType == "放射体检"
                                  ? _vm.columns2
                                  : _vm.columns,
                              sortable: "custom",
                              data: _vm.tableData,
                              "max-height": _vm.height,
                            },
                          }),
                          _c(
                            "Row",
                            {
                              staticClass: "page",
                              attrs: { type: "flex", justify: "end" },
                            },
                            [
                              _c("Page", {
                                attrs: {
                                  current: _vm.queryFrom.pageNumber,
                                  total: _vm.total,
                                  "page-size": _vm.queryFrom.pageSize,
                                  "page-size-opts": [10, 20, 50, 100, 200, 500],
                                  size: "small",
                                  "show-total": "",
                                  "show-elevator": "",
                                  "show-sizer": "",
                                  transfer: "",
                                },
                                on: {
                                  "on-change": _vm.changePage,
                                  "on-page-size-change": _vm.changePageSize,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "Card",
                    [_c("no-data", { attrs: { width: "150", height: "150" } })],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }